import { render, staticRenderFns } from "./faceEntry.vue?vue&type=template&id=b2a8220c&scoped=true&"
import script from "./faceEntry.vue?vue&type=script&lang=js&"
export * from "./faceEntry.vue?vue&type=script&lang=js&"
import style0 from "./faceEntry.vue?vue&type=style&index=0&id=b2a8220c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2a8220c",
  null
  
)

export default component.exports