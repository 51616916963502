<template>
   <div class="camera_outer">
      <el-tabs v-model="activeName" @tab-click="handleClick">
         <el-tab-pane label="当前客人" name="current">
            <el-col :span="8">
               <el-form :inline="true" :model="formInline" class="demo-form-inline" >
                  <el-form-item label="房间号：" label-width="110px">
                     <span>{{roomNo}}</span>
                  </el-form-item>
                  <el-form-item label="姓名：" label-width="110px">
                     <el-input v-model="formInline.peopleName" placeholder="姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="手机号：" label-width="110px">
                     <el-input v-model="formInline.peoplePhone" placeholder="手机号"></el-input>
                  </el-form-item>
                  <el-form-item label="性别：" label-width="110px">
                     <el-radio-group v-model="formInline.peopleSex">
                        <el-radio label="1">男</el-radio>
                        <el-radio label="2">女</el-radio>
                        <!--<el-radio label="保密"></el-radio>-->
                     </el-radio-group>
                  </el-form-item>
                  <el-form-item label="证件类型：" label-width="110px">
                     <el-select v-model="formInline.peopleCertType" placeholder="请选择" style="width: 178px;">
                        <el-option
                                v-for="item in certTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="证件号码：" label-width="110px">
                     <el-input v-model="formInline.peopleIdCard" placeholder="证件号码"></el-input>
                  </el-form-item>
                  <!--<el-form-item label="入住开始时间：" label-width="110px">
                     <el-input v-model="formInline.checkInStartTime" placeholder="入住开始时间"></el-input>
                  </el-form-item>
                  <el-form-item label="入住结束时间：" label-width="110px">
                     <el-input v-model="formInline.heckInEndTime" placeholder="入住结束时间"></el-input>
                  </el-form-item>-->
               </el-form>
            </el-col>
            <el-col :span="16">
               <el-row>
                  <el-col :span="12">
                     <p class="t-1">*客人头像</p>
                     <div class="camera-bg">
                        <!--<span class="row1"></span>
                        <span class="row2"></span>
                        <span class="col1"></span>
                        <span class="col2"></span>-->
                        <video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
                        <canvas style="display:none;" id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
                     </div>
                  </el-col>
                  <el-col :span="12">
                     <el-row class="take-photos">
                        <h6>注意事项</h6>
                        <p>1、照片中只能有一个人脸，参考证件照；</p>
                        <p>2、照片中人脸端正，保持正常表情；</p>
                        <p>3、照片中人脸占画面30%以上，防止遮挡；</p>
                        <p>4、避免角度倾侧，尽量露出双耳轮廓；</p>
                     </el-row>
                     <div v-if="imgSrc" class="img_bg_camera">
                        <img :src="imgSrc" alt="" class="tx_img">
                     </div>
                  </el-col>
               </el-row>
               <el-row>
                  <el-button  @click="getCompetence">打开摄像头</el-button>
                  <el-button  @click="stopNavigator">关闭摄像头</el-button>
                  <el-button  @click="setImage">拍照</el-button>
                  <el-button  @click="handleUpload">上传</el-button>
                  <el-button  @click="setImage">取消</el-button>
               </el-row>
            </el-col>

         </el-tab-pane>
         <el-tab-pane label="历史客人" name="history">
            <!-- 表头 -->
            <el-row>
               <span class="label">客人姓名：</span>
               <el-input class="width-175 m-bottom-5 m-right-10" v-model="realName" placeholder="客人姓名"  clearable></el-input>
               <span class="label">手机号码：</span>
               <el-input class="width-175 m-bottom-5 m-right-10" v-model="mobile" placeholder="手机号码"  clearable></el-input>
               <el-button class="m-bottom-5" type="primary" icon="el-icon-search" @click="handleQuery(true)" ><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button class="m-bottom-5" type="primary" icon="el-icon-refresh-right" @click="handleQuery(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
            <!-- 表格 -->
            <div class="table-box">
               <el-table
                       ref="singleTable"
                       v-loading="loading"
                       :data="tableData"
                       style="width: 100%"
                       :stripe="true"
                       >
                  <el-table-column label="序号" width="60">
                     <template slot-scope="scope">
                        {{scope.$index + 1}}
                     </template>
                  </el-table-column>
                  <el-table-column label="头像" width="70">
                     <template slot-scope="scope">
                        <img v-if="scope.row.member.headPortrait" :src="scope.row.member.headPortrait" width="40" height="40" class="suffix-cursor v-align-middle"/>
                        <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="40" height="40" class="suffix-cursor v-align-middle" alt="">
                     </template>
                  </el-table-column>
                  <el-table-column label="客人姓名" min-width="120"></el-table-column>
                  <el-table-column prop="member.gender" label="性别" min-width="120"></el-table-column>
                  <el-table-column prop="mobile" label="手机号码" min-width="120"></el-table-column>
                  <el-table-column prop="member.certificateNo" label="证件类型" min-width="80"></el-table-column>
                  <el-table-column label="证件号码" min-width="170"></el-table-column>
                  <el-table-column label="房间号" min-width="140"></el-table-column>
                  <el-table-column label="入住开始时间" min-width="140"></el-table-column>
                  <el-table-column prop="expireTime" label="入住结束时间" min-width="140"></el-table-column>
               </el-table>
            </div>
         </el-tab-pane>
      </el-tabs>
   </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   export default {
      props: {
         roomNo: String,
         roomId: String,
         hotelId: String,
      },
      data () {
         return {
            activeName: 'current',
            realName: '',
            mobile: '',
            page: 1,
            limit: 10,
            formInline: {
               peopleCertType: 'idCard',
               peopleSex: '1'
            },
            certTypes: [
               {value: 'idCard', label: '身份证'},
               {value: 'passport', label: '护照'},
               {value: 'pass', label: '港澳通行证'}
            ],
            videoWidth: 270,
            videoHeight: 300,
            imgSrc: '',
            thisCancas: null,
            thisContext: null,
            thisVideo: null,
            /* 历史客人 */
            tableData: [],
            loading: false,
            upload_success: '上传成功！'
         }
      },
      methods: {
         // 调用权限（打开摄像头功能）
         getCompetence () {
            var _this = this
            this.thisCancas = document.getElementById('canvasCamera')
            this.thisContext = this.thisCancas.getContext('2d')
            this.thisVideo = document.getElementById('videoCamera')
            // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
            if (navigator.mediaDevices === undefined) {
               navigator.mediaDevices = {}
            }
            // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
            // 使用getUserMedia，因为它会覆盖现有的属性。
            // 这里，如果缺少getUserMedia属性，就添加它。
            if (navigator.mediaDevices.getUserMedia === undefined) {
               navigator.mediaDevices.getUserMedia = function (constraints) {
                  // 首先获取现存的getUserMedia(如果存在)
                  var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia
                  // 有些浏览器不支持，会返回错误信息
                  // 保持接口一致
                  if (!getUserMedia) {
                     return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
                  }
                  // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
                  return new Promise(function (resolve, reject) {
                     getUserMedia.call(navigator, constraints, resolve, reject)
                  })
               }
            }
            var constraints = { audio: false, video: { width: this.videoWidth, height: this.videoHeight, transform: 'scaleX(-1)' } }
            navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
               // 旧的浏览器可能没有srcObject
               if ('srcObject' in _this.thisVideo) {
                  _this.thisVideo.srcObject = stream
               } else {
                  // 避免在新的浏览器中使用它，因为它正在被弃用。
                  _this.thisVideo.src = window.URL.createObjectURL(stream)
               }
               _this.thisVideo.onloadedmetadata = function (e) {
                  _this.thisVideo.play()
               }
            }).catch(err => {
               console.log(err)
            })
         },
         //  绘制图片（拍照功能）
         setImage () {
            var _this = this
            // 点击，canvas画图
            _this.thisContext.drawImage(_this.thisVideo, 0, 0, _this.videoWidth, _this.videoHeight)
            // 获取图片base64链接
            var image = this.thisCancas.toDataURL('image/png')
            _this.imgSrc = image
            this.$emit('refreshDataList', this.imgSrc)
         },
         // base64转文件
         dataURLtoFile (dataurl, filename) {
            var arr = dataurl.split(',')
            var mime = arr[0].match(/:(.*?);/)[1]
            var bstr = atob(arr[1])
            var n = bstr.length
            var u8arr = new Uint8Array(n)
            while (n--) {
               u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], filename, { type: mime })
         },
         // 关闭摄像头
         stopNavigator () {
            this.thisVideo.srcObject.getTracks()[0].stop()
         },
         // 上传照片
         handleUpload(){
            const url = urlObj.addHousehold
            const param = {
               peopleName: this.formInline.peopleName,
               peoplePhone: this.formInline.peoplePhone,
               peopleSex: this.formInline.peopleSex,
               peoplePhoto: this.imgSrc,
               hotelId: this.hotelId,
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.upload_success,
                     type: 'success'
                  })
               }
            })
         },
         // 切换tab
         handleClick(){
            if (this.activeName === 'history') this.getHotelCheckinList()
         },
         // 重置当前tab
         childMethod(){
            this.activeName = 'current'
         },
         // 获取入住客人记录
         getHotelCheckinList(){
            const url = urlObj.hotelCheckinList + `?page=${this.page}&limit=${this.limit}`
            const param = {
               roomId: this.roomId,
               mobile: this.mobile,
               realName: this.realName
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  console.log(res)
                  this.tableData = res.records.map(item => {
                     item.member ? "" : item.member = {}
                     return item
                  })
               }
            })
         },
         // 查询入住客人列表
         handleQuery(bool){
            if (bool) return this.getHotelCheckinList()
            this.mobile = ''
            this.realName = ''
         }
      },
   }
</script>
<style lang="scss" scoped>
   /deep/ .el-form-item__label{
      padding: 0 5px 0 0;
   }
   .t-1{
      text-align: center;
   }
   .take-photos h6{
      font-size: 16px;
      margin-bottom: 16px;
   }
   .take-photos p{
      line-height: 26px;
   }

   .camera-bg {
      padding: 15px;
      box-sizing: border-box;
      border: 1px solid transparent;
      border-radius: 7px;
      position: relative;
      text-align: center;
   }
   .camera-bg span {
      position: absolute;
      padding: 15px;
   }
   .row1 {
      top: -1px;
      left: -2px;
      border-left: 3px solid #999999;
      border-top: 2px solid #999999;
      border-top-left-radius: 5px;
   }
   .row2 {
      top: -1px;
      right: -2px;
      border-right: 3px solid #999999;
      border-top: 2px solid #999999;
      border-top-right-radius: 5px;
   }
   .col1 {
      bottom: -2px;
      left: -2px;
      border-left: 3px solid #999999;
      border-bottom: 2px solid #999999;
      border-bottom-left-radius: 5px;
   }

   .col2 {
      bottom: -2px;
      right: -2px;
      border-right: 3px solid #999999;
      border-bottom: 2px solid #999999;
      border-bottom-right-radius: 5px;
   }
   .camera_outer video{
      background: #f6f6f6;
   }

   .camera_outer{
      position: relative;
      overflow: hidden;
      // background: url("../../assets/img/user_0608_04.png") no-repeat center;
      background-size: 100%;
      video,canvas,.tx_img{
         -moz-transform:scaleX(-1);
         -webkit-transform:scaleX(-1);
         -o-transform:scaleX(-1);
         transform:scaleX(-1);
      }
      .btn_camera{
         position: absolute;
         bottom: 4px;
         left: 0;
         right: 0;
         height: 50px;
         background-color: rgba(0,0,0,0.3);
         line-height: 50px;
         text-align: center;
         color: #ffffff;
      }
      .bg_r_img{
         position: absolute;
         bottom: 0;
         left: 0;
         right: 0;
         top: 0;
      }
      .img_bg_camera{
         margin-top: 10px;
         width: 30%;
         img{
            width: 100%;
            height: 100%;
         }
         .img_btn_camera{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background-color: rgba(0,0,0,0.3);
            color: #ffffff;
            .loding_img{
               width: 50px;
               height: 50px;
            }
         }
      }
   }
</style>
