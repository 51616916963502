<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>AIOT</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
           <el-breadcrumb-item>房间管理</el-breadcrumb-item>
           <el-breadcrumb-item>房间列表</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <el-row class="content-box">
        <!-- 查询框 -->
        <el-row class="search-box">
           <el-row class="search-row">
              <el-row class="search-item">
                 <label>楼栋/单元：</label>
                 <el-cascader
                       class="width-220 m-right-10"
                       v-model="param.buildUnitId"
                       :options="buildList"
                       @change="changeBuilding"
                       clearable>
                 </el-cascader>
              </el-row>
              <el-row class="search-item">
                 <label>房间类型：</label>
                 <el-select  class="width-220" v-model="param.roomTypeId" :placeholder="$t('msg.select')" clearable>
                    <el-option v-for="(item, index) in roomTypes" :key="index" :label="item.label" :value="item.value"></el-option>
                 </el-select>
              </el-row>
              <el-row class="search-item">
                 <label>房间号：</label>
                 <el-input  class="width-220 m-bottom-5 m-right-10" v-model="param.roomNo" :placeholder="$t('msg.room_number')" clearable></el-input>
              </el-row>
              <el-button  class="m-bottom-5 bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
              <el-button  class="m-bottom-5" type="primary" @click="handleQuery(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
           </el-row>
        </el-row>
        <!-- 主体内容 -->
        <el-row class="table-box">
           <!-- 表格按钮 -->
           <div class="table-head-btn">
              <el-row></el-row>
              <el-row class="right-btn">
                 <el-button class="m-bottom-5 " type="primary" icon="el-icon-school"  @click="handleCheckIn" plain><span >模拟入住</span></el-button>
                 <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-plus"  @click="handleAdd" ><span >新建房间</span></el-button>
              </el-row>
           </div>
          <!-- 表格 -->
           <hotel-floor :floorOpt="floorList" @setFloorClick="getFloorClick"/>
           <el-table
                ref="roomTable"
                v-loading="loading"
                border fit
                :data="tableData"
                highlight-current-row
                @current-change="selectedRoom"
                :stripe="true">
                <el-table-column :label="$t('msg.number')" width="60">
                   <template slot-scope="scope">
                      {{scope.$index + 1}}
                   </template>
                </el-table-column>
                <el-table-column prop="roomNo" :label="$t('msg.room_num')"></el-table-column>
                <el-table-column prop="roomTypeName" :label="$t('msg.room_type')" min-width="110"></el-table-column>
                <el-table-column prop="floor" :label="$t('msg.floor_num')" min-width=""></el-table-column>
                <el-table-column prop="hotelName" :label="$t('msg.own_hotel')" min-width="150"></el-table-column>
                <el-table-column prop="createTime" :label="$t('msg.add_time')" min-width="150" sortable></el-table-column>
                <el-table-column prop="remark" :label="$t('msg.remark')" min-width="180"></el-table-column>
                <el-table-column :label="$t('msg.operating')" min-width="220" fixed="right">
                  <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-edit" @click.stop="handleEdit(scope.row)" >
                      <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                    </el-button>
                    <el-button type="text" style="color: #F56C6C;" icon="el-icon-delete" @click.stop="handleDel(scope.row.id)" >
                      <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                    </el-button>
                     <el-button type="text" icon="iconfont iconshualian" @click.stop="handleInput(scope.row)" >
                        <el-link type="primary">入住人员</el-link>
                     </el-button>
                  </template>
                </el-table-column>
              </el-table>
           <!-- 分页 -->
           <pagination :total="total" :page-size="param.limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
        </el-row>
        <!-- 新增/编辑房间 -->
         <el-dialog :title="action === 'add' ? '新增房间' : '编辑房间'" :visible.sync="isShow" width="550px" :before-close="handleClose">
            <el-form class="form-dialog-box">
               <el-form-item required label="楼栋/单元">
                  <el-cascader
                            v-model="buildUnitId"
                            :options="buildList"
                            @change="changeBuilding"
                            clearable
                            class="width-300"></el-cascader>
               </el-form-item>
               <el-form-item required label="房间楼层">
                  <el-select  class="width-300" v-model="floor" filterable :placeholder="$t('msg.select')" clearable>
                     <el-option v-for="(item,index) in floorOpt" :key="index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item required label="房间类型">
                  <el-select  class="width-300" v-model="roomTypeId" :placeholder="$t('msg.select')" clearable>
                     <el-option v-for="(item,index) in roomTypes" :key="index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item style="width:100%" required label="新增方式">
                  <template>
                     <el-radio v-model="newMethod" label="1"><span v-text="$t('msg.roomtype_one')">一间</span></el-radio>
                     <el-radio v-model="newMethod" label="2"><span v-text="$t('msg.roomtype_batch')">批量</span></el-radio>
                  </template>
               </el-form-item>
               <el-form-item required label="房间编号">
                  <el-input v-if="newMethod === '1'"  class="width-300" v-model="roomNo" :placeholder="$t('msg.room_number')" clearable></el-input>
                  <el-col class="room-margin" v-else>
                     <el-input  class="width-90" v-model="prefix" :placeholder="$t('msg.prefix')" clearable></el-input>
                     <el-input  class="width-100" v-model="startRoomNo" :placeholder="$t('msg.starting_room_num')" clearable></el-input>
                     <span v-text="'—'"></span>
                     <el-input  class="width-100" v-model="cutoffRoomNo" :placeholder="$t('msg.cutoff_room_num')" clearable></el-input>
                     <el-input  class="width-90" v-model="suffix" :placeholder="$t('msg.suffix')" clearable></el-input>
                  </el-col>
               </el-form-item>
               <el-form-item required label="PMS房间号">
                  <el-input v-if="newMethod === '1'"  class="width-300" v-model="pmsRoomNo" placeholder="PMS房间号" clearable></el-input>
                  <el-col class="room-margin" v-else>
                     <el-input  class="width-90" v-model="pmsPrefix" :placeholder="$t('msg.prefix')" clearable></el-input>
                     <el-input  class="width-100" v-model="startPmsRoomNo" :placeholder="$t('msg.starting_room_num')" clearable></el-input>
                     <span v-text="'—'"></span>
                     <el-input  class="width-100" v-model="cutoffPmsRoomNo" :placeholder="$t('msg.cutoff_room_num')" clearable></el-input>
                     <el-input  class="width-90" v-model="pmsSuffix" :placeholder="$t('msg.suffix')" clearable></el-input>
                  </el-col>
               </el-form-item>
               <el-form-item required label="备注信息">
                  <el-input  class="width-300 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="resetRoom" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="newMethod === '1' ? handleSave() : handleSaveBatch()" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
        <!-- 模拟入住 -->
        <el-form label-width="105px">
           <el-dialog :title="$t('msg.add_check_in')" :visible.sync="isShowIn" width="750px" :before-close="handleCloseIn">
              <el-row class="m-bottom-15">
                 <el-col :span="6" class="t-right l-height-26"><span v-text="$t('msg.hotel_name_a')">酒店名称：</span></el-col>
                 <el-col :span="16">
                    <span v-text="hotelName"></span>
                 </el-col>
              </el-row>
              <el-row class="m-bottom-15">
                 <el-col :span="6" class="t-right l-height-26"><span v-text="$t('msg.room_num_a')">房间号：</span></el-col>
                 <el-col :span="16">
                    <span v-text="inRoomNo"></span>
                 </el-col>
              </el-row>
              <el-row class="m-bottom-15">
                 <el-col :span="6" class="t-right l-height-26"><span v-text="$t('msg.user_source_a')">用户来源：</span></el-col>
                 <el-col :span="16" class="l-height-26">
                    <template>
                       <el-radio v-model="source" label="1"><span v-text="$t('msg.temporary_visit')">临时访问</span></el-radio>
                       <el-radio v-model="source" label="2"><span v-text="$t('msg.member_user')">会员用户</span></el-radio>
                    </template>
                 </el-col>
              </el-row>
              <el-row class="m-bottom-15" v-if="source === '1'">
                 <el-col :span="6" class="t-right l-height-26"><span v-text="$t('msg.phone_number_a')">手机号码：</span></el-col>
                 <el-col :span="16">
                    <el-input  class="width-434" v-model="mobile" :placeholder="$t('msg.phone_number')" clearable></el-input>
                 </el-col>
              </el-row>
              <el-row class="m-bottom-15" v-if="source === '2'">
                 <el-col :span="6" class="t-right l-height-26"><span v-text="$t('msg.user_name_a')">用户名：</span></el-col>
                 <el-col :span="16">
                    <el-input  class="width-434 l-height-30" :placeholder="$t('msg.user_name')" clearable>
                       <i slot="suffix" class="suffix-cursor el-icon-user-solid"></i>
                    </el-input>
                 </el-col>
              </el-row>
              <el-row class="m-bottom-15">
                 <el-col :span="6" class="t-right l-height-26"><span v-text="$t('msg.remarks_a')">备注信息：</span></el-col>
                 <el-col :span="16">
                    <el-input class="width-434 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
                 </el-col>
              </el-row>
              <span slot="footer" class="dialog-footer">
             <el-button  @click="resetCheckIn()" v-text="$t('msg.reset')">重置</el-button>
             <el-button  type="primary" @click="saveCheckIn()" v-text="$t('msg.save')">保存</el-button>
           </span>
           </el-dialog>
        </el-form>
        <!-- 人员入住 -->
        <el-form label-width="105px">
           <el-dialog title="人员入住" :visible.sync="isShowInput" width="1000px" :before-close="closePhotograph">
              <el-row>
                 <face-entry ref="child" :roomNo="roomNo" :hotelId="hotelInfo.id" :roomId="id"/>
              </el-row>
           </el-dialog>
        </el-form>
     </el-row>
  </section>
</template>

<script>
   import faceEntry from '@/components/local/faceEntry.vue' // 引入子组件(拍照)
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   import { mapState } from 'vuex'
   export default {
      components: {
         faceEntry
      },
      data () {
         return {
            tableData: [],      // 表格数据
            buildList: [],      // 楼栋列表
            floorList: [
               { label: '全部', value: '', disabled: true }
            ],      // 楼层列表
            roomTypes: [],      // 房间类型列表
            total: 0,           // 数据总数
            loading: true,      // 表格加载效果
            isShow: false,      // 是否显示添加弹窗
            isShowIn: false,    // 是否显示模拟入住弹窗
            isShowInput: false, // 是否显示刷脸录入弹窗
            action: '',         // 当前行为
            boxIcon: true,      // 酒店显示
            newMethod: '1',     // 新增方式
            isNewMethod: true,  // 是否显示新增方式
            prefix: '',         // 房号前缀
            startRoomNo: '',    // 开始房号
            cutoffRoomNo: '',   // 截止房号
            suffix: '',         // 房号后缀
            pmsPrefix: '',      // PMS房号前缀
            startPmsRoomNo: '', // 开始PMS房号
            cutoffPmsRoomNo: '',// 截止PMS房号
            pmsSuffix: '',      // PMS房号后缀
            param: {
               limit: 0,
               page: 1,
               buildUnitId: [],
               floor: '',
               roomTypeId: '',
               roomNo: ''
            },
            /* 新建房间表单字段 */
            id: '',             // 房间ID
            buildUnitId: [],    // 楼栋ID
            floorOpt:[],        // 房间楼层
            roomNo: '',         // 房号
            pmsRoomNo: '',      // pms房间号
            floor: '',          // 楼层
            roomTypeId: '',     // 房型ID
            remark: '',         // 备注
            /* 模拟入住模块 */
            hotelName: '',      // 酒店名称
            inRoomNo: '',       // 房号
            source: '1',        // 用户来源
            roomId: '',         // 房间ID
            storeId: '',        // 商户ID
            mobile: '',         // 手机号
            //remark: '',
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            prompt: '提示！',
            check_in_success: '模拟入住成功！',
            select_success: '请选中当前房间！',
            add_success: '添加成功！',
            update_success: '修改成功！',
            confirm_remove: '确定移除？',
            remove_success: '移除成功！',
            room_no_null: '房间号不能为空！',
            own_hotel_no_null: '所属酒店不能为空！',
            room_floor_no_null: '房间楼层不能为空！',
            room_type_nos_null: '房间类型不能为空！',
            star_room_no_null: '开始房间号不能为空！',
            end_room_no_null: '结束房间号不能为空！',
            room_more_than: '开始房间号不能大于结束房间号！',
         }
      },
      computed:{
         ...mapState(['hotelInfo'])
      },
      mounted() {
         this.param.limit = sessionStorage.getItem('pageSize') * 1
         this.getRooms()
         this.getBuildings()
         this.getRoomTypes()
      },
      methods: {
         // 获取房间列表
         getRooms(){
            const url = urlObj.roomPage
            let { buildUnitId, ...par } = this.param
            const param = { hotelId: this.hotelInfo.id, buildUnitId: buildUnitId[1], ...par }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  this.tableData = res.records
                  this.$refs.roomTable.setCurrentRow(this.tableData[0])
               }
            })
         },
         // 搜索房间
         handleQuery(bool){
            if (bool) return this.getRooms()
            this.floorList = this.floorList.slice(0, 1)
            this.param.buildUnitId = []
            this.param.floor = ''
            this.param.roomTypeId = ''
            this.param.roomNo = ''
         },
         // 改变每页数
         pageChange(num){
            this.param.limit = num
            this.getRooms()
         },
         // 改变当前页
         currChange(num){
            this.param.page = num
            this.getRooms()
         },
         // 获取楼栋列表
         getBuildings(){
            const url = urlObj.buildAndUnitList + `/${this.hotelInfo.id}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let buildList = res.records
                  this.buildList = buildList.map(item1 => {
                     let children = []
                     if (item1.buildUnits.length > 0){
                        children = item1.buildUnits.map(item2 => {
                           return {value: item2.id, label: item2.unitName}
                        })
                     }
                     return {value: item1.id, label: item1.buildName, children}
                  })
               }
            })
         },
         // 获取楼栋单元层级
         changeBuilding(val){
            this.getFloors(val[1])
         },
         // 获取当前楼层列表
         getFloors(unitVal){
            this.floorList = []
            this.floorOpt = []
            const url = urlObj.getUnit + `/${unitVal}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let data = res.records
                  let beginFloor = data.beginFloor
                  let endFloor = data.endFloor
                  if (!endFloor || endFloor <= 0) return
                  let obj = {};
                  for (let i = beginFloor; i <= endFloor; i++) {
                     obj.value = i
                     obj.label = i
                     this.floorList.push(obj)
                     obj = {}
                  }
                  this.floorList = this.floorList.filter(item => item.label !== 0)
                  this.floorOpt = this.floorList
               }
            })
         },
         // 点击当前楼层
         getFloorClick(val) {
            this.param.floor = val.floor
            this.getRooms()
         },
         // 获取房间类型
         getRoomTypes(){
            const url = urlObj.getHotelRoomTypeList
            const param = { hotelId: this.hotelInfo.id }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let roomTypes = res.records
                  this.roomTypes = roomTypes.map(item => ({value: item.roomTypeId, label: item.roomTypeName}))
               }
            })
         },
         // 添加房间
         handleAdd(){
            this.isShow = true
            this.action = 'add'
         },
         // 编辑房间
         handleEdit(row){
            this.isShow = true
            this.action = 'edit'
            this.buildUnitId.push(row.buildId, row.buildUnitId)
            this.isNewMethod = false
            this.id = row.id
            this.roomNo = row.roomNo
            this.floor = row.floor
            this.roomTypeId = row.roomTypeId
            this.remark = row.row
            this.getBuildings()
         },
         // 重置表单
         resetRoom(){
            this.buildUnitId = []
            this.newMethod = '1'
            this.roomNo = ''
            this.floor = ''
            this.roomTypeId = ''
            this.remark = ''
            this.prefix = ''
            this.startRoomNo = ''
            this.cutoffRoomNo = ''
            this.suffix = ''
         },
         // 保存操作
         handleSave(){
            // 验证表单
            if (!this.floor) {
               this.$alert(this.room_floor_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.roomTypeId) {
               this.$alert(this.room_type_nos_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.roomNo) {
               this.$alert(this.room_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.pmsRoomNo) {
               this.$alert(this.room_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = urlObj.addRoom
            let param = {
               hotelId: this.hotelInfo.id,
               buildUnitId: this.buildUnitId[1],
               roomNo: this.roomNo,
               pmsRoomNo: this.pmsRoomNo,
               floor: this.floor,
               roomTypeId: this.roomTypeId,
               remark: this.remark,
               source: "PC" // 数据来源
            }
            if (this.action === 'edit') {
               param.id = this.id
               url = urlObj.editRoom
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.addRoom = false
                  this.getRooms()
                  this.handleClose()
               }
            })
         },
         // 开始房间号,结束房间号拼接
         filterStartEnd(start, end, dPrefix, dSuffix) {
            let str = ""
            let number = end - start
            if (start === end) {
               str = dPrefix + start + dSuffix
            } else if (start < end) {
               str = dPrefix + start + dSuffix + ','
               for (let i = 0; i < number; i++) {
                  str += dPrefix + (end--) + dSuffix + ','
               }
            }
            return str
         },
         // 保存批量新建
         handleSaveBatch(){
            // 表单验证
            if (!this.floor) {
               this.$alert(this.room_floor_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.roomTypeId) {
               this.$alert(this.room_type_nos_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.startRoomNo) {
               this.$alert(this.star_room_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.cutoffRoomNo) {
               this.$alert(this.end_room_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 开始房间号大于结束房间号
            if (Number(this.startRoomNo) > Number(this.cutoffRoomNo)) {
               this.$alert(this.room_more_than, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.startPmsRoomNo) {
               this.$alert(this.star_room_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.cutoffPmsRoomNo) {
               this.$alert(this.end_room_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 开始房间号大于结束房间号
            if (Number(this.startPmsRoomNo) > Number(this.cutoffPmsRoomNo)) {
               this.$alert(this.room_more_than, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            const url = urlObj.batchAddRoom
            const param = {
               buildUnitId: this.buildUnitId[1],
               rooms: this.filterStartEnd(Number(this.startRoomNo), Number(this.cutoffRoomNo),this.prefix,this.suffix),
               pmsRoomNo: this.filterStartEnd(Number(this.startPmsRoomNo), Number(this.cutoffPmsRoomNo),this.pmsPrefix,this.pmsSuffix),
               hotelId: this.hotelInfo.id,
               floor: this.floor,
               roomTypeId: this.roomTypeId,
               remark: this.remark,
               source: "PC"
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.add_success,
                     type: 'success'
                  })
                  this.getRooms()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 关闭弹窗
         handleClose(){
            this.isShow = false
            this.resetRoom()
         },
         // 删除房间
         handleDel(id) {
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               const url = urlObj.delRoom + `/${id}`
               this.$axios.get(url, {}).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getRooms()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
         // 刷脸录入
         handleInput(row){
            this.id = row.id
            this.roomNo = row.roomNo
            this.isShowInput = true
         },
         // 关闭刷新录入弹窗
         closePhotograph(){
            this.id = ''
            this.roomNo = ''
            this.isShowInput = false
            this.$refs.child.childMethod()
         },
         // 表格当前选中
         selectedRoom(val){
            if (val) {
               this.hotelName = val.hotelName
               this.inRoomNo = val.roomNo
               this.roomId = val.id
            }
         },
         // 模拟入住
         handleCheckIn(){
            this.isShowIn = true
            this.getStoreId()
         },
         // 模拟入住 获取商户ID
         getStoreId(){
            const url = urlObj.hotelDetail + `/${this.hotelInfo.id}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  this.storeId = res.records.storeId
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 模拟入住 保存操作
         saveCheckIn () {
            const url = urlObj.userCheckIn
            const param = {
               hotelId: this.hotelInfo.id,
               roomId: this.roomId,
               storeId: this.storeId,
               mobile: this.mobile,
               remark: this.remark
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.check_in_success,
                     type: 'success'
                  })
                  this.handleCloseIn()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 模拟入住 重置表单
         resetCheckIn(){
            this.storeId = ''
            this.mobile = ''
            this.remark = ''
         },
         // 模拟入住 关闭弹窗
         handleCloseIn(){
            this.isShowIn = false
            this.resetCheckIn()
         }
      },
      watch:{
         hotelInfo(newVal,oldVal) {
            oldVal && newVal.id !== oldVal.id && this.getRooms()
         }
      },
   }
</script>

<style scoped lang="scss">
  .room-margin{
    /deep/ .el-input, span{
      margin-right: 10px;
    }
  }
   /deep/ .iconshualian{
      margin-right: 4px;
      vertical-align:text-top;
   }

  .camera_outer{
     position: relative;
     overflow: hidden;
     // background: url("../../assets/img/user_0608_04.png") no-repeat center;
     background-size: 100%;
     video,canvas,.tx_img{
        -moz-transform:scaleX(-1);
        -webkit-transform:scaleX(-1);
        -o-transform:scaleX(-1);
        transform:scaleX(-1);
     }
     .btn_camera{
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        height: 50px;
        background-color: rgba(0,0,0,0.3);
        line-height: 50px;
        text-align: center;
        color: #ffffff;
     }
     .bg_r_img{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
     }
     .img_bg_camera{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        img{
           width: 100%;
           height: 100%;
        }
        .img_btn_camera{
           position: absolute;
           bottom: 0;
           left: 0;
           right: 0;
           height: 50px;
           line-height: 50px;
           text-align: center;
           background-color: rgba(0,0,0,0.3);
           color: #ffffff;
           .loding_img{
              width: 50px;
              height: 50px;
           }
        }
     }
  }
</style>
